import { useEffect } from 'react';

export function useDocumentTitle(domain: string) {
  useEffect(() => {
    // Format the domain for display (remove http/https if present)
    const formattedDomain = domain.replace(/^https?:\/\//, '');
    
    // Set the document title
    document.title = `${formattedDomain} is for sale`;
    
    // Optional: Restore the original title when component unmounts
    return () => {
      document.title = 'ParkBidSell - Domain Marketplace';
    };
  }, [domain]);
} 