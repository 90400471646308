import React, { useState, useEffect } from 'react';
import * as Slider from '@radix-ui/react-slider';

interface BidSliderProps {
  initialValue?: number;
  onBidChange: (value: number) => void;
  valuation?: DomainValuation;
}

interface DomainValuation {
  value: number;
  confidence: number;
  reasoning: string;
}

export function BidSlider({ initialValue, onBidChange, valuation }: BidSliderProps) {
  const [value, setValue] = useState(initialValue || 0);
  const maxValue = valuation?.value ? Math.max(valuation.value * 2, 10000) : 10000;
  const step = Math.max(Math.floor(maxValue / 100), 100);

  const handleValueChange = (newValue: number[]) => {
    setValue(newValue[0]);
    onBidChange(newValue[0]);
  };

  // Reset value when valuation changes
  useEffect(() => {
    if (valuation) {
      setValue(0);
      setTimeout(() => {
        setValue(valuation.value);
      }, 1000);
    }
  }, [valuation]);

  return (
    <div className="space-y-6">
      {/* Slider Section */}
      <div className="relative pt-8 pb-12">
        <div className="flex justify-between items-center absolute w-full" style={{ top: '-2rem' }}>
          <div className="text-lg text-gray-400">Your Offer</div>
          {valuation && (
            <div className="flex items-center gap-2">
              <span className="text-sm text-blue-400">AI Valuation: ${valuation.value.toLocaleString()}</span>
              <span className="text-sm text-gray-500">{Math.round(valuation.confidence * 100)}% confidence</span>
            </div>
          )}
        </div>
        <div className="relative">
          <Slider.Root
            className="relative flex items-center select-none touch-none h-5 w-full"
            value={[value]}
            onValueChange={handleValueChange}
            max={maxValue}
            step={step}
          >
            <Slider.Track className="bg-gray-800 relative grow rounded-full h-[3px]">
              <Slider.Range className="absolute bg-blue-500 rounded-full h-full" />
            </Slider.Track>
            <Slider.Thumb
              className="block w-4 h-4 bg-blue-500 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              aria-label="Value"
            >
              <div className="absolute -top-8 left-1/2 transform -translate-x-1/2">
                <div className="bg-blue-500 text-white px-2 py-1 rounded text-sm whitespace-nowrap">
                  ${value.toLocaleString()}
                </div>
              </div>
            </Slider.Thumb>
          </Slider.Root>
        </div>
      </div>
    </div>
  );
} 