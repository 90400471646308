import React from 'react';
import { getDomainIcon, getDomainColors } from '../utils/domainAnalysis';

interface DynamicLogoProps {
  domain: string;
  size?: number;
  className?: string;
}

export function DynamicLogo({ domain, size = 40, className = '' }: DynamicLogoProps) {
  const colors = getDomainColors(domain);
  
  // Get first letter of domain (excluding TLD)
  const firstLetter = domain.split('.')[0].charAt(0).toUpperCase();
  
  return (
    <div 
      className={`relative group ${className}`}
      style={{ width: size, height: size }}
    >
      {/* Main logo background with gradient */}
      <div 
        className="absolute inset-0 rounded-xl bg-gradient-to-br from-[#1a1f35] to-[#0d1126] p-[1px]"
      >
        <div 
          className="absolute inset-[1px] rounded-[11px] opacity-90 transition-opacity duration-300 group-hover:opacity-100"
          style={{
            background: `linear-gradient(135deg, ${colors.primary}, ${colors.secondary})`,
            animation: 'gradient 8s ease infinite',
            backgroundSize: '200% 200%'
          }}
        />
      </div>

      {/* Letter */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-white font-bold transition-transform duration-300 group-hover:scale-110"
             style={{ fontSize: size * 0.6 }}>
          {firstLetter}
        </div>
      </div>

      {/* Shine effect */}
      <div 
        className="absolute inset-0 rounded-xl opacity-0 group-hover:opacity-20 transition-opacity duration-300"
        style={{
          background: 'linear-gradient(45deg, transparent 40%, rgba(255,255,255,0.4) 50%, transparent 60%)',
          backgroundSize: '200% 200%',
          animation: 'shine 3s infinite'
        }}
      />
    </div>
  );
}

// Add animations to your global CSS
const styles = document.createElement('style');
styles.innerHTML = `
  @keyframes gradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }

  @keyframes shine {
    0% { background-position: 200% 50%; }
    100% { background-position: -200% 50%; }
  }
`;
document.head.appendChild(styles); 