import React from 'react';
import { Toaster } from 'sonner';

export function ToastContainer() {
  return (
    <div className="fixed inset-x-0 top-0 z-50 flex justify-center">
      <div className="w-full md:w-[500px]">
        <Toaster
          position="top-center"
          toastOptions={{
            style: {
              background: 'transparent',
              border: 'none',
              boxShadow: 'none',
              padding: 0,
              width: '100%',
              maxWidth: '100%',
              margin: 0,
            },
            unstyled: true,
            className: 'mt-4',
            duration: 5000,
          }}
        />
      </div>
    </div>
  );
}