import type { OfferFormData } from '../../types';

// Default to production URL, fallback to localhost only in development
const API_URL = import.meta.env.VITE_API_URL || 'https://park-bid-sell-worker.billing8690.workers.dev';

interface PayloadType {
  domain: string;
  name: string;
  email: string;
  phone?: string;
  offer: string;
  message?: string;
  timestamp: string;
  offerType: 'purchase' | 'rent';
}

export async function submitOffer(data: OfferFormData) {
  console.log('Starting submission process...');
  console.log('Using API URL:', API_URL);
  console.log('Form data:', data);

  // Create payload object
  const payload: PayloadType = {
    domain: window.location.hostname,
    name: data.name,
    email: data.email,
    phone: data.phone || undefined,
    offer: String(data.offer),
    message: data.message || undefined,
    timestamp: new Date().toISOString(),
    offerType: data.offerType
  };

  console.log('Sending payload:', payload);

  try {
    const response = await fetch(`${API_URL}/submit-offer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Server response:', response.status, errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log('Response received:', responseData);

    return {
      success: true,
      message: responseData.message || 'Request sent'
    };
  } catch (error) {
    console.error('Error submitting form:', error);
    throw error;
  }
}