import React from 'react';
import { TagIcon } from 'lucide-react';

export function DomainBadges() {
  return (
    <div className="flex justify-center">
      <div 
        className="
          relative group
          flex items-center gap-3 px-6 py-2.5
          bg-gradient-to-r from-blue-500/20 to-purple-500/20
          rounded-full border border-blue-400/30
          shadow-[0_0_30px_-5px_rgba(96,165,250,0.3)]
          hover:shadow-[0_0_30px_-5px_rgba(96,165,250,0.5)]
          transition-all duration-300
        "
      >
        <div className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10 animate-gradient-x" />
        <TagIcon 
          className="w-5 h-5 text-blue-400" 
          strokeWidth={2.5} 
        />
        <span className="text-[17px] font-bold tracking-wide bg-gradient-to-r from-blue-400 via-blue-300 to-purple-400 bg-clip-text text-transparent">
          Domain For Sale
        </span>
      </div>
    </div>
  );
} 