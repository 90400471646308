import React, { createContext, useContext } from 'react';
import { Globe } from 'lucide-react';
import { OfferForm } from './components/OfferForm';
import { ToastContainer } from './components/ToastContainer';
import { DomainBadges } from './components/DomainBadges';
import { useDocumentTitle } from './hooks/useDocumentTitle';
import { DynamicLogo } from './components/DynamicLogo';
import { getDomainIndustry } from './utils/domainAnalysis';
import { AnimatedBackground } from './components/AnimatedBackground';
import DomainValuation from './components/DomainValuation';

// Create a context for the domain
export const DomainContext = createContext<string>('');

export default function App() {
  // Use motomail.us for testing when on localhost
  const hostname = window.location.hostname;
  const domain = hostname === 'localhost' ? 'motomail.us' : hostname;
  
  useDocumentTitle(`${domain} is for sale`);

  return (
    <DomainContext.Provider value={domain}>
      <AnimatedBackground>
        <div className="max-w-[500px] mx-auto px-4 py-12 md:py-20">
          {/* Logo and Domain */}
          <div className="flex flex-col items-center gap-8 mb-16">
            <DynamicLogo domain={domain} size={80} />
            <div className="space-y-3 text-center">
              <h1 className="text-4xl md:text-5xl font-semibold text-white">
                {domain}
              </h1>
              <DomainBadges />
            </div>
          </div>

          {/* Description */}
          <div className="text-center mb-16">
            <h2 className="text-2xl md:text-3xl text-white font-medium">
              Make a risk-free offer to the owner of this domain name.
            </h2>
          </div>

          <OfferForm />
          
          {/* Add DomainValuation to handle the modal */}
          <DomainValuation 
            domain={domain}
            value={0}  // This will be updated with actual valuation
            confidence={0}
            reasoning=""
          />
        </div>
      </AnimatedBackground>
      <ToastContainer />
    </DomainContext.Provider>
  );
}

// Export a hook to use the domain context
export const useDomain = () => {
  const domain = useContext(DomainContext);
  if (!domain) {
    throw new Error('useDomain must be used within a DomainContext.Provider');
  }
  return domain;
};