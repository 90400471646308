import React, { useEffect, useRef } from 'react';

export function AnimatedBackground({ children }: { children: React.ReactNode }) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Set canvas size
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    // Stripe-like gradient animation
    let gradient: CanvasGradient;
    let t = 0;

    const animate = () => {
      t += 0.002;
      
      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Create gradient
      gradient = ctx.createLinearGradient(
        0, 0,
        canvas.width, canvas.height
      );

      // Stripe's signature colors with slight tech twist
      gradient.addColorStop(0, `hsl(${220 + Math.sin(t) * 10}, 70%, 20%)`);
      gradient.addColorStop(0.3, `hsl(${260 + Math.sin(t + 1) * 10}, 70%, 15%)`);
      gradient.addColorStop(0.6, `hsl(${200 + Math.sin(t + 2) * 10}, 70%, 12%)`);
      gradient.addColorStop(1, `hsl(${240 + Math.sin(t + 3) * 10}, 70%, 8%)`);

      // Fill background
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return (
    <div className="min-h-screen relative overflow-hidden">
      {/* Animated gradient background */}
      <canvas
        ref={canvasRef}
        className="fixed inset-0 w-full h-full"
        style={{ opacity: 0.8 }}
      />

      {/* Subtle grid overlay */}
      <div 
        className="fixed inset-0 opacity-[0.05]"
        style={{
          backgroundImage: `
            linear-gradient(90deg, #fff 1px, transparent 1px),
            linear-gradient(#fff 1px, transparent 1px)
          `,
          backgroundSize: '64px 64px'
        }}
      />

      {/* Glow effects */}
      <div className="fixed inset-0">
        <div 
          className="absolute top-[-10%] left-[20%] w-[500px] h-[500px] rounded-full opacity-30 blur-[100px]"
          style={{
            background: 'linear-gradient(180deg, rgba(99, 102, 241, 0.3) 0%, rgba(59, 130, 246, 0.3) 100%)'
          }}
        />
        <div 
          className="absolute bottom-[-10%] right-[20%] w-[500px] h-[500px] rounded-full opacity-30 blur-[100px]"
          style={{
            background: 'linear-gradient(180deg, rgba(59, 130, 246, 0.3) 0%, rgba(99, 102, 241, 0.3) 100%)'
          }}
        />
      </div>

      {/* Content */}
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
} 