import { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { submitOffer } from '../services/api';
import confetti from 'canvas-confetti';

interface ModalOfferFormData {
  name: string;
  email: string;
  phone?: string;
  offer: string;
  message?: string;
  offerType: 'purchase' | 'rent';
}

const OfferModal = ({ isOpen, onClose, domain, suggestedValue }: { 
  isOpen: boolean; 
  onClose: () => void;
  domain: string;
  suggestedValue: number;
}) => {
  const [formData, setFormData] = useState<ModalOfferFormData>({
    name: '',
    email: '',
    phone: '',
    offer: suggestedValue.toString(),
    message: '',
    offerType: 'purchase'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);

    try {
      await submitOffer({
        ...formData,
        offer: Number(formData.offer)
      });
      setIsSuccess(true);
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });
      setTimeout(() => {
        onClose();
        setIsSuccess(false);
        setFormData({
          name: '',
          email: '',
          phone: '',
          offer: suggestedValue.toString(),
          message: '',
          offerType: 'purchase'
        });
      }, 2000);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to submit offer. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900/90 backdrop-blur-sm">
      <div className="min-h-screen px-4 text-center">
        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>

        <div className="inline-block w-full max-w-md text-left align-middle transition-all transform">
          {/* Modal content */}
          <div className="relative bg-gradient-to-b from-gray-800 to-gray-900 rounded-2xl shadow-2xl overflow-hidden">
            {/* Header */}
            <div className="px-6 py-4 bg-gray-800/50 border-b border-gray-700">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent">
                  Make an Offer
                </h2>
                <button
                  type="button"
                  onClick={onClose}
                  className="text-gray-400 hover:text-gray-300 transition-colors"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
            </div>

            {/* Domain info */}
            <div className="px-6 py-4 bg-gray-800/30">
              <h3 className="text-lg font-medium text-white">
                {domain}
              </h3>
              <p className="text-blue-400 font-medium mt-1">
                Suggested value: ${suggestedValue.toLocaleString()}
              </p>
            </div>

            {/* Form */}
            <div className="px-6 py-6">
              {error && (
                <div className="mb-6 p-4 bg-red-500/10 border border-red-500/20 rounded-lg">
                  <p className="text-red-400 text-sm">{error}</p>
                </div>
              )}

              {isSuccess ? (
                <div className="text-center py-8">
                  <div className="inline-flex items-center justify-center w-20 h-20 rounded-full bg-green-500/20 mb-4">
                    <svg className="w-10 h-10 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-bold text-white mb-2">Offer Submitted!</h3>
                  <p className="text-gray-400">We'll be in touch with you shortly.</p>
                </div>
              ) : (
                <form onSubmit={handleSubmit} className="space-y-6">
                  {/* Name field */}
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1.5">
                      Your Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      required
                      className="w-full px-4 py-3 bg-gray-800/50 border border-gray-700 rounded-lg
                        text-white placeholder-gray-500
                        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                        transition-all duration-200"
                      placeholder="John Smith"
                      value={formData.name}
                      onChange={e => setFormData({ ...formData, name: e.target.value })}
                    />
                  </div>

                  {/* Email field */}
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1.5">
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      required
                      className="w-full px-4 py-3 bg-gray-800/50 border border-gray-700 rounded-lg
                        text-white placeholder-gray-500
                        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                        transition-all duration-200"
                      placeholder="you@example.com"
                      value={formData.email}
                      onChange={e => setFormData({ ...formData, email: e.target.value })}
                    />
                  </div>

                  {/* Phone field */}
                  <div>
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-300 mb-1.5">
                      Phone Number <span className="text-gray-500">(optional)</span>
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      className="w-full px-4 py-3 bg-gray-800/50 border border-gray-700 rounded-lg
                        text-white placeholder-gray-500
                        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                        transition-all duration-200"
                      placeholder="+1 (555) 123-4567"
                      value={formData.phone}
                      onChange={e => setFormData({ ...formData, phone: e.target.value })}
                    />
                  </div>

                  {/* Offer amount */}
                  <div>
                    <label htmlFor="offer" className="block text-sm font-medium text-gray-300 mb-1.5">
                      Your Offer
                    </label>
                    <div className="relative">
                      <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500">$</span>
                      <input
                        type="number"
                        id="offer"
                        required
                        min="1"
                        className="w-full pl-8 pr-4 py-3 bg-gray-800/50 border border-gray-700 rounded-lg
                          text-white placeholder-gray-500
                          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                          transition-all duration-200"
                        value={formData.offer}
                        onChange={e => setFormData({ ...formData, offer: e.target.value })}
                      />
                    </div>
                  </div>

                  {/* Message field */}
                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-1.5">
                      Message <span className="text-gray-500">(optional)</span>
                    </label>
                    <textarea
                      id="message"
                      rows={4}
                      className="w-full px-4 py-3 bg-gray-800/50 border border-gray-700 rounded-lg
                        text-white placeholder-gray-500
                        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                        transition-all duration-200 resize-none"
                      placeholder="Any additional details you'd like to share..."
                      value={formData.message}
                      onChange={e => setFormData({ ...formData, message: e.target.value })}
                    />
                  </div>

                  {/* Action buttons */}
                  <div className="flex flex-col sm:flex-row gap-3 pt-4">
                    <button
                      type="button"
                      onClick={onClose}
                      disabled={isSubmitting}
                      className="w-full sm:w-1/2 px-6 py-3 text-sm font-medium text-gray-300
                        bg-gray-800 hover:bg-gray-700
                        rounded-lg border border-gray-700
                        focus:outline-none focus:ring-2 focus:ring-gray-500
                        disabled:opacity-50 disabled:cursor-not-allowed
                        transition-all duration-200"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="w-full sm:w-1/2 px-6 py-3 text-sm font-medium text-white
                        bg-gradient-to-r from-blue-500 to-blue-600
                        hover:from-blue-600 hover:to-blue-700
                        rounded-lg
                        focus:outline-none focus:ring-2 focus:ring-blue-500
                        disabled:opacity-50 disabled:cursor-not-allowed
                        transition-all duration-200
                        relative overflow-hidden"
                    >
                      {isSubmitting ? (
                        <span className="flex items-center justify-center">
                          <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                          </svg>
                          Submitting...
                        </span>
                      ) : (
                        'Submit Offer'
                      )}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function DomainValuation({ domain, value: initialValue, confidence: initialConfidence, reasoning: initialReasoning }: {
  domain: string;
  value: number;
  confidence: number;
  reasoning: string;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [currentConfidence, setCurrentConfidence] = useState(initialConfidence);
  const [currentReasoning, setCurrentReasoning] = useState(initialReasoning);

  useEffect(() => {
    const handleOpenModal = (event: CustomEvent<{
      bid: number;
      value: number;
      confidence: number;
      reasoning: string;
    }>) => {
      setCurrentValue(event.detail.value);
      setCurrentConfidence(event.detail.confidence);
      setCurrentReasoning(event.detail.reasoning);
      setIsModalOpen(true);
    };

    window.addEventListener('openOfferModal', handleOpenModal as EventListener);
    return () => {
      window.removeEventListener('openOfferModal', handleOpenModal as EventListener);
    };
  }, []);

  return (
    <div className="space-y-6">
      <OfferModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        domain={domain}
        suggestedValue={currentValue}
      />
    </div>
  );
} 