import type { OfferFormData } from '../../types';
import type { ApiResponse } from './types';
import { submitOffer as submitOfferToWebhook } from './client';

export async function submitOffer(data: OfferFormData): Promise<ApiResponse> {
  try {
    return await submitOfferToWebhook(data);
  } catch (error) {
    console.error('Form submission error:', error);
    throw new Error('Failed to submit offer. Please try again.');
  }
}