// Base value for algorithmic fallback
const BASE_VALUE = 500;

export interface ResearchStep {
  id: string;
  label: string;
  status: 'pending' | 'active' | 'complete';
  findings?: string;
}

export interface ResearchProgress {
  steps: ResearchStep[];
  onStepComplete: (stepId: string, findings?: string) => void;
  onStepStart: (stepId: string) => void;
}

export interface DomainValuation {
  value: number;
  confidence: number;
  reasoning: string;
  historicalContext?: {
    wayback: string;
    dns: string;
    whois: string;
    wikipedia?: string;
    searchResults?: string;
  };
  valuationSteps?: {
    wayback: number;
    dns: number;
    whois: number;
    wikipedia?: number;
    searchResults?: number;
    final: number;
  };
  steps: ResearchStep[];
}

export async function estimateDomainValue(domain: string, progress?: ResearchProgress): Promise<DomainValuation> {
  const steps: ResearchStep[] = [
    { id: 'wayback', label: 'Checking Archive History', status: 'pending' },
    { id: 'dns', label: 'Checking DNS History', status: 'pending' },
    { id: 'whois', label: 'Checking Registration', status: 'pending' },
    { id: 'wikipedia', label: 'Wikipedia check', status: 'pending' },
    { id: 'searchResults', label: 'Checking Search Results', status: 'pending' },
    { id: 'final', label: 'Finalizing Valuation', status: 'pending' }
  ];

  function updateStep(stepId: string, status: 'pending' | 'active' | 'complete', findings?: string) {
    const step = steps.find(s => s.id === stepId);
    if (step) {
      step.status = status;
      if (findings) step.findings = findings;
    }
  }

  try {
    // Clean domain
    const cleanDomain = domain.replace(/^(https?:\/\/)?(www\.)?/, '');
    console.log('Cleaned domain:', cleanDomain);

    // Initialize valuation data
    const valuationData: DomainValuation = {
      value: BASE_VALUE,
      confidence: 0.85,
      reasoning: '',
      isAI: true,
      historicalContext: {
        wayback: '',
        dns: '',
        whois: '',
      },
      valuationSteps: {
        wayback: 0,
        dns: 0,
        whois: 0,
        final: 0
      },
      steps
    };

    // Use worker for main valuation
    const workerUrl = import.meta.env.VITE_API_URL || 'https://park-bid-sell-worker.billing8690.workers.dev';
    const shouldBustCache = new URLSearchParams(window.location.search).get('nocache') === 'true';

    // Start each step
    updateStep('wayback', 'active');
    updateStep('dns', 'active');
    updateStep('whois', 'active');
    updateStep('wikipedia', 'active');
    updateStep('searchResults', 'active');

    const response = await fetch(`${workerUrl}/api/value-domain${shouldBustCache ? '?nocache=true' : ''}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify({ 
        domain: cleanDomain
      })
    });

    if (!response.ok) {
      throw new Error(`Valuation request failed: ${response.status} ${response.statusText}`);
    }

    const workerValuation = await response.json();

    // Update steps based on worker response
    if (workerValuation.historicalContext) {
      updateStep('wayback', 'complete', workerValuation.historicalContext.wayback);
      updateStep('dns', 'complete', workerValuation.historicalContext.dns);
      updateStep('whois', 'complete', workerValuation.historicalContext.whois);
      updateStep('wikipedia', 'complete', workerValuation.historicalContext.wikipedia);
      updateStep('searchResults', 'complete', workerValuation.historicalContext.searchResults);
    }

    // Final step
    updateStep('final', 'active');
    setTimeout(() => updateStep('final', 'complete'), 1000);

    return {
      ...workerValuation,
      steps
    };

  } catch (err) {
    console.error('Domain analysis failed:', err);
    // Mark all steps as complete with error
    steps.forEach(step => {
      updateStep(step.id, 'complete', 'Check failed');
    });
    return {
      value: BASE_VALUE,
      confidence: 0.7,
      reasoning: "Basic valuation due to error.",
      isAI: false,
      steps
    };
  }
} 