import React, { useState, useEffect } from 'react';
import { BidSlider } from './BidSlider';
import { ResearchProgress } from './ResearchProgress';
import { estimateDomainValue, ResearchStep } from '../utils/domainValuation';
import { useDomain } from '../App';

interface DomainValuation {
  value: number;
  confidence: number;
  reasoning: string;
  historicalContext?: {
    wayback: string;
    dns: string;
    whois: string;
    wikipedia?: string;
    searchResults?: string;
  };
  valuationSteps?: {
    wayback: number;
    dns: number;
    whois: number;
    wikipedia?: number;
    searchResults?: number;
    final: number;
  };
  steps: ResearchStep[];
}

export function OfferForm() {
  const domain = useDomain();
  const [bid, setBid] = useState<number>(0);
  const [valuation, setValuation] = useState<DomainValuation | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState<ResearchStep[]>([
    { id: 'wayback', label: 'Checking Archive History', status: 'pending' },
    { id: 'dns', label: 'Checking DNS History', status: 'pending' },
    { id: 'whois', label: 'Checking Registration', status: 'pending' },
    { id: 'wikipedia', label: 'Wikipedia check', status: 'pending' },
    { id: 'searchResults', label: 'Checking Search Results', status: 'pending' },
    { id: 'final', label: 'Finalizing Valuation', status: 'pending' }
  ]);

  const handleBidChange = (value: number) => {
    setBid(value);
  };

  // Start valuation automatically when component mounts
  useEffect(() => {
    const startValuation = async () => {
      setIsLoading(true);
      setValuation(null); // Reset valuation
      
      // Reset steps
      setSteps(prevSteps => 
        prevSteps.map(step => ({ ...step, status: 'pending', findings: undefined }))
      );

      try {
        // Start the first step
        setSteps(prevSteps => {
          const newSteps = [...prevSteps];
          if (newSteps[0]) newSteps[0].status = 'active';
          return newSteps;
        });

        const result = await estimateDomainValue(domain);
        
        // Update steps with findings
        if (result.steps) {
          setSteps(result.steps);
        }

        // Set valuation after a short delay to allow for step animations
        setTimeout(() => {
          setValuation(result);
          setIsLoading(false);
        }, 1000);
      } catch (error) {
        console.error('Error getting valuation:', error);
        setIsLoading(false);
      }
    };

    startValuation();
  }, [domain]);

  const handleMakeOffer = () => {
    if (!valuation) return;
    
    const event = new CustomEvent('openOfferModal', {
      detail: {
        bid,
        value: valuation.value,
        confidence: valuation.confidence,
        reasoning: valuation.reasoning
      }
    });
    window.dispatchEvent(event);
  };

  return (
    <div className="space-y-8">
      <BidSlider
        onBidChange={handleBidChange}
        valuation={valuation || undefined}
      />
      
      {/* Valuation reasoning */}
      {valuation?.reasoning && (
        <div className="p-4 rounded-lg bg-gray-900/30 border border-blue-500/10">
          <p className="text-sm text-gray-400">
            {valuation.reasoning}
          </p>
        </div>
      )}
      
      {/* Only show the research progress */}
      <ResearchProgress steps={steps} />

      <button
        type="button"
        className="w-full px-6 py-3 text-lg font-medium text-white
          rounded-xl bg-gradient-to-r from-blue-500 to-blue-600
          hover:from-blue-600 hover:to-blue-700
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
          disabled:opacity-50 disabled:cursor-not-allowed
          transition-all duration-200"
        disabled={isLoading || !valuation}
        onClick={handleMakeOffer}
      >
        {isLoading ? 'Analyzing Domain...' : 'Make Offer'}
      </button>
    </div>
  );
}