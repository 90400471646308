import React, { useEffect, useState } from 'react';

interface ResearchStep {
  id: string;
  label: string;
  status: 'pending' | 'active' | 'complete';
  findings?: string;
}

interface ResearchProgressProps {
  steps: ResearchStep[];
  className?: string;
}

export function ResearchProgress({ steps, className = '' }: ResearchProgressProps) {
  const [displaySteps, setDisplaySteps] = useState<ResearchStep[]>(steps);

  // Update display steps whenever steps prop changes
  useEffect(() => {
    setDisplaySteps(steps);
  }, [steps]);

  return (
    <div className={`space-y-2 ${className}`}>
      {displaySteps.map((step, index) => (
        <div 
          key={step.id}
          className={`
            flex flex-col gap-1
            rounded-lg bg-gray-900/30 border border-blue-500/10
            transition-all duration-300
            ${step.status === 'active' ? 'border-blue-500/30' : ''}
            overflow-hidden
          `}
        >
          {/* Main step row */}
          <div className="flex items-center gap-3 px-4 py-2">
            {/* Status indicator */}
            <div className="relative flex items-center justify-center w-4 h-4">
              {step.status === 'pending' ? (
                <div className="w-2 h-2 rounded-full bg-gray-700" />
              ) : step.status === 'active' ? (
                <>
                  <div className="absolute w-4 h-4 rounded-full bg-blue-500/20" />
                  <div className="absolute w-4 h-4 rounded-full bg-blue-500/30 animate-ping" />
                  <div className="absolute w-3 h-3 rounded-full bg-blue-500/60 animate-pulse" />
                </>
              ) : (
                <div className="w-4 h-4 rounded-full bg-blue-500/50" />
              )}
            </div>

            {/* Label */}
            <span className={`
              text-sm transition-colors duration-300
              ${step.status === 'pending' ? 'text-gray-500' : 
                step.status === 'active' ? 'text-blue-400' : 
                'text-gray-400'}
            `}>
              {step.label}
            </span>
          </div>

          {/* Findings */}
          {step.findings && step.status === 'complete' && (
            <div className="px-4 py-2 bg-gray-900/50 border-t border-blue-500/10">
              <p className="text-sm text-gray-400">
                {step.findings}
              </p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
} 