import { 
  Flame, Activity, Heart, ShoppingBag, Briefcase, Code, 
  Laptop, Leaf, Zap, Music, Camera, Book, Utensils, 
  Dumbbell, Car, Home, Plane, Palette, Globe, Bitcoin,
  Baby, Shirt, PawPrint, Sun, Cloud, Coffee, Wine,
  Smartphone, Gamepad, Headphones, type LucideIcon
} from 'lucide-react';

interface CategoryMatch {
  keywords: string[];
  icon: LucideIcon;
  industry: string;
  colors: {
    primary: string;
    secondary: string;
  };
}

const categories: CategoryMatch[] = [
  {
    keywords: ['tech', 'software', 'app', 'digital', 'cyber', 'web', 'net', 'byte', 'data', 'cloud'],
    icon: Laptop,
    industry: 'Technology',
    colors: { primary: '#3B82F6', secondary: '#60A5FA' }
  },
  {
    keywords: ['eco', 'green', 'earth', 'nature', 'bio', 'organic', 'garden', 'plant'],
    icon: Leaf,
    industry: 'Environmental',
    colors: { primary: '#10B981', secondary: '#34D399' }
  },
  {
    keywords: ['sport', 'fit', 'gym', 'athletic', 'run', 'train', 'muscle', 'health'],
    icon: Dumbbell,
    industry: 'Fitness',
    colors: { primary: '#EC4899', secondary: '#F472B6' }
  },
  {
    keywords: ['food', 'eat', 'meal', 'cook', 'kitchen', 'dish', 'recipe', 'chef'],
    icon: Utensils,
    industry: 'Food & Dining',
    colors: { primary: '#F59E0B', secondary: '#FBBF24' }
  },
  {
    keywords: ['shop', 'store', 'market', 'buy', 'retail', 'cart', 'purchase'],
    icon: ShoppingBag,
    industry: 'Retail',
    colors: { primary: '#8B5CF6', secondary: '#A78BFA' }
  },
  {
    keywords: ['business', 'corp', 'office', 'work', 'pro', 'service', 'consult'],
    icon: Briefcase,
    industry: 'Business',
    colors: { primary: '#1F2937', secondary: '#374151' }
  },
  {
    keywords: ['art', 'design', 'creative', 'studio', 'gallery', 'craft'],
    icon: Palette,
    industry: 'Creative',
    colors: { primary: '#EC4899', secondary: '#F472B6' }
  },
  {
    keywords: ['music', 'audio', 'sound', 'radio', 'tune', 'band', 'song'],
    icon: Music,
    industry: 'Music',
    colors: { primary: '#6366F1', secondary: '#818CF8' }
  },
  {
    keywords: ['photo', 'video', 'film', 'camera', 'picture', 'image'],
    icon: Camera,
    industry: 'Photography',
    colors: { primary: '#8B5CF6', secondary: '#A78BFA' }
  },
  {
    keywords: ['edu', 'learn', 'school', 'academy', 'course', 'study', 'teach'],
    icon: Book,
    industry: 'Education',
    colors: { primary: '#059669', secondary: '#34D399' }
  },
  {
    keywords: ['crypto', 'coin', 'token', 'blockchain', 'bitcoin', 'eth'],
    icon: Bitcoin,
    industry: 'Cryptocurrency',
    colors: { primary: '#F59E0B', secondary: '#FBBF24' }
  },
  {
    keywords: ['baby', 'kid', 'child', 'toy', 'family', 'parent'],
    icon: Baby,
    industry: 'Children & Family',
    colors: { primary: '#EC4899', secondary: '#F472B6' }
  },
  {
    keywords: ['fashion', 'wear', 'cloth', 'style', 'dress', 'apparel'],
    icon: Shirt,
    industry: 'Fashion',
    colors: { primary: '#8B5CF6', secondary: '#A78BFA' }
  },
  {
    keywords: ['pet', 'dog', 'cat', 'animal', 'vet', 'paw'],
    icon: PawPrint,
    industry: 'Pets & Animals',
    colors: { primary: '#F59E0B', secondary: '#FBBF24' }
  },
  {
    keywords: ['game', 'play', 'fun', 'entertainment', 'gaming'],
    icon: Gamepad,
    industry: 'Gaming',
    colors: { primary: '#6366F1', secondary: '#818CF8' }
  },
  {
    keywords: ['travel', 'trip', 'tour', 'vacation', 'holiday', 'journey'],
    icon: Plane,
    industry: 'Travel',
    colors: { primary: '#0EA5E9', secondary: '#38BDF8' }
  },
  {
    keywords: ['weather', 'climate', 'forecast', 'sun', 'sky'],
    icon: Sun,
    industry: 'Weather',
    colors: { primary: '#F59E0B', secondary: '#FBBF24' }
  },
  {
    keywords: ['coffee', 'cafe', 'tea', 'drink', 'beverage'],
    icon: Coffee,
    industry: 'Cafe & Beverages',
    colors: { primary: '#78350F', secondary: '#92400E' }
  },
  {
    keywords: ['wine', 'bar', 'drink', 'alcohol', 'spirit', 'brew'],
    icon: Wine,
    industry: 'Beverages & Spirits',
    colors: { primary: '#BE123C', secondary: '#E11D48' }
  },
  // Fallback category
  {
    keywords: ['global', 'world', 'international', 'universal'],
    icon: Globe,
    industry: 'General',
    colors: { primary: '#3B82F6', secondary: '#60A5FA' }
  }
];

export function analyzeDomain(domain: string): CategoryMatch {
  // Remove common TLDs and split into words
  const cleanDomain = domain.toLowerCase()
    .replace(/\.(com|net|org|io|co|app|ai|dev|me|us|uk|eu)$/, '')
    .split(/[.-]/)
    .filter(Boolean);

  // Score each category based on keyword matches
  const scores = categories.map(category => {
    const score = category.keywords.reduce((total, keyword) => {
      return total + cleanDomain.reduce((sum, word) => {
        // Exact match gets higher score
        if (word === keyword) return sum + 2;
        // Partial match gets lower score
        if (word.includes(keyword) || keyword.includes(word)) return sum + 1;
        return sum;
      }, 0);
    }, 0);
    return { category, score };
  });

  // Sort by score and get the best match
  const bestMatch = scores.sort((a, b) => b.score - a.score)[0];
  
  // If no good matches found, return the fallback category
  return bestMatch.score > 0 ? bestMatch.category : categories[categories.length - 1];
}

// Helper function to get consistent colors for a domain
export function getDomainColors(domain: string): { primary: string; secondary: string } {
  const match = analyzeDomain(domain);
  return match.colors;
}

// Helper function to get the icon component for a domain
export function getDomainIcon(domain: string): LucideIcon {
  const match = analyzeDomain(domain);
  return match.icon;
}

// Helper function to get the suggested industry for a domain
export function getDomainIndustry(domain: string): string {
  const match = analyzeDomain(domain);
  return match.industry;
} 